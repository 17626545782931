import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";

import "src/components/products/style.scss";
import "src/components/products/thermoProtect/style.scss";
import ThermoProtectFichaTecnica from "src/components/products/thermoProtect/FichaTecnica";

const ThermoProtect = (props) => (
  <LayoutGeneral
    {...{ ...props, productName: "ThermoProtect", showPageTitle: false }}
  >
    <Content className="product thermoProtect fichaTecnica">
      <ThermoProtectFichaTecnica />
    </Content>
  </LayoutGeneral>
);

export default ThermoProtect;
