import React from "react";

import { Divider, List } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";

export const ThermoProtectFichaTecnica = () => {
  const data = [
    {
      content: (
        <>
          <b className="paragraphTitle">Composición</b>
          Dispersión de poliacrilato, dióxido de titanio, óxido de zinc, caolín,
          carbonato de calcio, silicatos, agua, propilenglicol, celulosa,
          conservantes
          <b className="paragraphTitle">Grupo de productos/Código SIG</b>
          BSW20 Es de aplicación la información contenida en la ficha de datos
          de seguridad vigente.
          <b className="paragraphTitle">Áreas de aplicación</b>
          ClimateCoating ThermoProtect es un recubrimiento protector resistente
          adecuado para su uso en exterior, sobre enfoscados de mortero de cal
          hidráulica, enfoscados de mortero de cemento y cal, y ladrillo
          silicocalcáreo. La especial composición de ClimateCoating
          ThermoProtect, proporciona una amplia gama de áreas de aplicación
          energética para generar un escudo térmico tanto en verano como en
          invierno.
          <b className="paragraphTitle">Propiedades específicas del producto</b>
          Permeabilidad variable al vapor de agua, alta resistencia a
          influencias ambientales agresivas como la niebla tóxica (smog) y el
          ozono troposférico, libre de emisiones y disolventes, alta resistencia
          a los rayos UV y a los agentes climatológicos, reflectante a la
          radiación solar, muy alta resistencia del color, genera condiciones de
          confort térmico. Regula la humedad en superficies permeables
          <b className="paragraphTitle">Datos del material</b>
          <ul>
            <li>Valores sd según DIN 52615</li>
            <li>zona seca: sd = 1,3 zona húmeda: sd = 0,7</li>
            <li>Densidad 1,05 kg/dm3 según ISO 2811-1</li>
            <li>Valor PH: 8,00 – 9,20 a 20 °C</li>
            <li>Comportamiento frente al fuego: C-s1, d0</li>
            <li>Resistencia química: disponible bajo consulta</li>
          </ul>
          <b className="paragraphTitle">Reglamento de COV (CE)</b>
          Valor límite de 01.01.2010 para material de revestimiento (Prod.Cat.
          c) en base de agua (Wb) para paredes exteriores de material de
          construcción mineral (mate) = 40 g/l. Este producto contiene {"< 3"}
          g/l VOC.
          <b className="paragraphTitle">Superficies de aplicación</b>
          ClimateCoating ThermoProtect se puede aplicar sobre todas las
          superficies sólidas, limpias, secas, libres de óxido y de grasa, como
          enfoscado viejo y nuevo y superficies firmes previamente pintadas. Se
          recomienda una superficie permeable para garantizar las propiedades
          energéticas del producto.
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle">Preparación del soporte</b>
          <ul>
            <li>
              Superficies de enfoscado en bruto, manchas de enfoscado: limpiar,
              neutralizar soportes alcalinos (en su caso) e imprimar con FixPlus
            </li>
            <li>Sintéticos: analizar la capacidad de adherencia</li>
            <li>
              Metales: dependiendo del metal y la tensión, imprimar RustPrimer o
              ZincPrimer
            </li>
            <li>
              Revestimiento anterior no viable: dependiendo del revestimiento,
              eliminar por lavado, limpieza ácida
            </li>
            <li>Superficies que interfieren en la adherencia: lavar, lijar</li>
          </ul>
          <b className="paragraphTitle">Aplicación</b>
          El producto se puede aplicar mediante brocha, rodillo o método de
          pulverización. Para la primera aplicación se requieren dos capas.
          <br />
          ClimateCoating ThermoProtect se suministra listo para su uso. En caso
          de aplicación a través de equipos de pulverización, se deben seguir
          las instrucciones del fabricante del equipo.
          <br /> Para su aplicación en superficies muy rugosas o mediante el uso
          de equipos de pulverización, la consistencia del producto se puede
          regular añadiendo una pequeña cantidad de agua. Durante la aplicación,
          es importante mantener la temperatura del aire y del objeto a un
          mínimo de 5°C. Esto se aplica igualmente al período de secado. Una
          suficiente luz diurna promueve el secado del ligante reticulado UV que
          contiene el producto. <br />
          Remueva con un aparato mezclador antes de la aplicación o después de
          largas interrupciones del trabajo. Permita un período de secado de 12
          horas entre la aplicación de dos capas sucesivas.
          <b className="paragraphTitle">Consumo</b>
          El rendimiento de ClimateCoating ThermoProtect es de aproximadamente
          330 ml/m2, calculado para dos capas, sobre superficies lisas de baja
          porosidad. La capacidad de esparcimiento variará según la estructura y
          la porosidad de la superficie. En caso de duda, primero se debe
          aplicar sobre un área de prueba pequeña.
          <b className="paragraphTitle">Limpieza de herramientas</b>
          Los rodillos, brochas y equipos de pulverización deben lavarse a fondo
          con agua inmediatamente después de su uso.
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle"> Colores</b>
          ClimateCoating ThermoProtect se puede tintar según los sistemas de
          color NCS, RAL y ClimateCoating Edition 1. Por favor, compruebe la
          tonalidad del color antes de la aplicación.
          <b className="paragraphTitle">Almacenamiento y transporte</b>
          ClimateCoating ThermoProtect debe almacenarse y transportarse en
          ambiente frío, pero protegido contra heladas. Una vez abiertos, los
          recipientes deben ser cerrados firmemente, y aplicado el producto lo
          antes posible. Almacenable a partir de la fecha de fabricación durante
          12 meses.
          <b className="paragraphTitle">Volumen de suministro</b>5 litros, 12,5
          litros. Diferentes tamaños de envase disponibles bajo petición.
          <b className="paragraphTitle">Gestión de residuos</b>
          No vacíe los restos de producto en la red de evacuación, en cursos de
          agua o el terreno. Sólo los recipientes completamente vaciados deben
          ser desechados para su reciclaje. Por favor, deposite los productos
          parcialmente utilizados en un punto de gestión de residuos autorizado.
          Código de residuos no peligrosos según la lista de residuos de la UE:
          080120
          <b className="paragraphTitle">Aviso de seguridad</b>
          El contenido de la ficha técnica no manifiesta ninguna relación
          jurídica contractual. En cualquier caso, el aplicador/cliente tiene
          que considerar si el material es utilizable en condiciones prácticas
          antes de la aplicación. No permitir la entrada en la red de
          evacuación, en cursos de agua o el terreno. Mantener alejado de
          alimentos, bebidas y piensos. Mantener fuera del alcance de los niños.
          Proteger las superficies que no deben tratarse tomando las medidas
          adecuadas. Evitar el contacto con la piel y los ojos. Cuando se
          aplique con el método de pulverización, evitar la inhalación de polvo
          emergente. La información anteriormente mencionada dejará de ser
          válida al publicarse una nueva ficha técnica consecuencia de la mejora
          del producto. La información proporcionada fue verificada por
          ClimateCoating GmbH en laboratorio y en la práctica como valores de
          referencia. La información del producto se ofrece en base a nuestro
          mejor conocimiento y de acuerdo con el estado actual de la tecnología.
          Influencias ambientales, materiales, equipos de aplicación y métodos
          de aplicación están fuera de nuestro control y, por lo tanto, fuera de
          nuestra responsabilidad. ClimateCoating ©2019
        </>
      ),
    },
  ];

  return (
    <>
      <div className="linkBack">
        <Link to="/productos/thermoprotect/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a ThermoProtect</span>
        </Link>
      </div>{" "}
      <div className="productDocHeader">
        <h1 className="centered">Ficha Técnica ThermoProtect</h1>
      </div>
      <Divider />
      <div style={{ marginBottom: "50px" }}>
        <List
          grid={{ gutter: 24, column: 3 }}
          dataSource={data}
          renderItem={({ content }) => <List.Item>{content}</List.Item>}
        />
      </div>
    </>
  );
};

export default ThermoProtectFichaTecnica;
